.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content p {
  margin-bottom: 10px;
}

.spacedata {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;

  z-index: 1001;
  margin-bottom: 20px;
}
.spacedata ul {
  list-style: none;
}

.inner {
  border: 1px solid rgb(33, 226, 114);
  padding: 2%;
  margin-top: 1%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
