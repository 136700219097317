/* reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* make the body take up the whole viewport */
body {
  height: 100vh;
  overflow-x: hidden;
}

/* style the navbar */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 9999;
  transition: background-color 0.5s ease;
}

nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-evenly;
  gap: 130px;
}

nav ul li:not(:last-child) {
  margin-right: 1rem;
}

nav ul li a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

nav ul li a:hover {
  border-bottom: solid 1px #f5f8f8;
}

/* style the sections */
.section {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: black;
}

.section h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  margin-top: 10%;
  font-size: 3rem;
  font-weight: lighter;
  margin-bottom: 1rem;
  color: black;
  border: none;
  border: 1px solid white;
  padding: 5px;
  cursor: pointer;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  box-shadow: 0px 0px 20px 0px rgba(10, 10, 10, 0.75);
  border-radius: 10px;
}

.section h2:hover {
  background-color: rgb(116, 111, 111);
  color: white;
  border: none;
  transition: background-color 0.3sec ease;
  box-shadow: 0px 0px 20px 0px rgba(243, 240, 240, 0.75);
}
.section .image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  margin-top: -1.8rem;
  margin-bottom: -1.8rem;
}
